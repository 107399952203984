import { PersonalizedOffersRecommendation } from "@shared-ui/retail-recommendations-list";
import * as React from "react";
import { OffersRecommendationProps } from "./typings";
import { observer } from "mobx-react";
import { withStores } from "stores";

export const OffersRecommendation: React.FC<OffersRecommendationProps> = withStores("context")(
  observer((props: OffersRecommendationProps) => {
    const { templateComponent, context } = props;
    if (!templateComponent) {
      return null;
    }

    const { customTopMargin, ratingStarIcon, configurationId } = templateComponent.config;

    return (
      <PersonalizedOffersRecommendation
        inputs={{ tripId: null, configurationId }}
        customTopMargin={customTopMargin}
        ratingStarIcon={ratingStarIcon}
        isVrbo={context?.site?.brand == "vrbo"}
        isHeaderHidden
      />
    );
  })
);

export default OffersRecommendation;
